import React from "react";
import { Text, StandardLink } from './Layout/pageParts';

const Profile = () => { 
  return (
        <Text>Hello and welcome! My name is Gemma, I am a mama of two wonderful boys, a <StandardLink href="http://www.yogabygemma.co.uk">yoga teacher</StandardLink> and someone who is passionate about the change essential oils can bring to your life and that of your family. I first started using
          essential oils as part of my <StandardLink href="http://www.yogabygemma.co.uk">yoga</StandardLink> practice several years ago. Since then I've been on a journey to learn
          more and more about how they can be used. Not a day goes by now when I don't use an oil, it could be as simple as a drop of wild orange to flavour my
          water or perhaps a custom blend to fight off the dreaded seasonal colds. The more research I do the more amazing uses I find for them, for example I now make
          my own cleaning products and beauty products. There are 100's of pure essential oils that can
          be used for a variety of purposes. Once you begin to consider blending these to achieve unique goals the possibilities are endless.         
        </Text>       
  )
}

export default Profile;