import React from "react";
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image";
import Layout from "../components/Layout/layout"
import {  Text, TwoColContainer, Col1Image, Col2Info } from '../components/Layout/pageParts';
import Profile from '../components/profile';

const AboutPage = () => {

    const data = useStaticQuery(graphql`
    query {
      fileName: file(relativePath: { eq: "bg3.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
                  ...GatsbyImageSharpFluid

          }
        }
      }
    }
`)
     return (
     <Layout>
          <TwoColContainer>
            <Col1Image>
                <Img alt="about" fluid={data.fileName.childImageSharp.fluid} style={{ width: "100%", height: "100%" }} imgStyle={{ objectPosition: 'left' }} />
            </Col1Image>
            <Col2Info>       
                <Profile />
                <br/>
                <br/>
                <Text>I have used essential oils from a number of different suppliers over the last few years but kept coming back
                    to essential oils from doTERRA. There really is a doTERRA difference, the quality of the oils is in my opinion unmatched.
                    As such I only use doTERRA oils in my Reap collection so you can be assured of getting the very best quality. 
                </Text>
                <br/>
                <br/>                
                <Text>
                If you'd like to get started using essential oils to support you and your families health and wellbeing you are in the right place.
                I've been doing this for many years now and I would love to help you on your journey.
                </Text>
            </Col2Info>
        </TwoColContainer>
      </Layout>
      )      
    };

export default AboutPage;